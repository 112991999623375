@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.text-sm-special {
  font-size: 0.7rem
    /* 14px */
  ;
  line-height: 1.25rem
    /* 20px */
  ;
}

.text-xs-special {
  font-size: 0.6rem
    /* 12px */
  ;
  line-height: 1rem
    /* 16px */
  ;
}

.loader {
  border-top-color: grey;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slick-prev:before,
.slick-next:before {
  color: grey;
}

.max-w-xxs {
  max-width: 12rem;
}

.tooltip {
  z-index: 0;
}

.overlay {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100% - 64px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.sidebar {
  z-index: 20;
}