.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.slide-up-enter {
  transform: translateY(20px);
  opacity: 0;
}
.slide-up-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 500ms, opacity 500ms;
}
.slide-up-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-exit-active {
  transform: translateY(20px);
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}
